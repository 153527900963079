import "../styles/hero/five.scss";
import HeaderWhite from "../components/HeaderWhite";
import FeaturesFour from "../features/FeaturesFour";

const HeroFive = () => {
  return (
    <>
      <div className="hero__five__container">
        <div className="hero__five__wrapper">
          <div className="hero__five__top">
            <a href="https://app.pionexfinancepro.com">
              <span className="hero__five__tag">
                <p className="hero__five__tag__label">New!</p>

                <span className="hero__five__tag__text">
                  <p> Trade NFTs on our platform</p>
                  <img src="/icons/arrow-right.svg" alt="right" />
                </span>
              </span>
            </a>
            <h1 className="hero__five__title">
              {/* A new way to explore the Pionex Finance Pro */}
              <span> {""} A</span>
              <span> {""} new</span>
              <span> {""} way</span>
              <span> {""} to</span>
              <span> {""} explore</span>
              <span> {""} the</span>
              <span> {""} global</span>
              <span> {""} markets</span>
            </h1>

            <p className="hero__five__text">
              Trade the markets with our secure software and stay on top of your
              account. Trade anywhere, anytime and from any device
            </p>
            {/* 
        <a href="https://app.pionexfinancepro.com">
        <button className='hero__five__button'> Get started </button>
        </a> */}

            <span className="hero__six__buttons">
              {/* <button className="hero__six__button__white">Learn more</button> */}

              <a href="https://app.pionexfinancepro.com">
                <button className="cta__three__button__blue">
                  Get started
                </button>
              </a>
            </span>
          </div>

          <figure className="hero__five__desktop__figure">
            <img src="/screenshots/hero_five_desktop.png" alt="screenshot" />
          </figure>

          <figure className="hero__five__mobile__figure">
            <img src="/screenshots/hero_three_mobile.png" alt="screenshot" />
          </figure>
        </div>
      </div>
    </>
  );
};

export default HeroFive;
